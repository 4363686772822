//
document.addEventListener('DOMContentLoaded', function() {
  var initDataElement = document.getElementById('app-init-data');
  var initData = {
    mode: initDataElement.dataset.mode,
    rails_env: initDataElement.dataset.railsEnv,
    project_name: initDataElement.dataset.projectName,
    locale: initDataElement.dataset.locale,
    default_locale: initDataElement.dataset.defaultLocale,
    preferred_locales: initDataElement.dataset.preferredLocales,
    logged_in: initDataElement.dataset.loggedIn === 'true',
    site_name: initDataElement.dataset.siteName,
    session_timeout: parseInt(initDataElement.dataset.sessionTimeout),
    login_path: initDataElement.dataset.loginPath
  };

  csync.AppInit = new csync.App(initData);
  csync.batch_actions_views = {};
  new csync.Views.ProjectChangeDropdown({el: 'select#change-project'});
});

