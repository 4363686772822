import $ from 'jquery'; 

// DOM element selectors
const selectors = {
  body: 'body',
  menuToggle: '#menu_toggle',
  sidebarMenu: '#sidebar-menu',
  leftCol: '.left-col',
  rightCol: '.right-col',
  navMenu: '.nav_menu',
  footer: 'footer',
  parentNavItem: '.parent-nav-item',
  childMenuHide: '.child-menu-hide ul',
  childMenuContainer: '[data-child-menu]',
  childNavContainer: '.nav-md.nav-child'
};

// DOM elements
const DOM = {
  currentUrl: window.location.pathname.split('#')[0].split('?')[0],
  body: $(selectors.body),
  menuToggle: $(selectors.menuToggle),
  sidebarMenu: $(selectors.sidebarMenu),
  leftCol: $(selectors.leftCol),
  rightCol: $(selectors.rightCol),
  navMenu: $(selectors.navMenu),
  footer: $(selectors.footer),
  childMenuContainer: $(selectors.childMenuContainer)
};

// Sidebar functionality
const sidebar = {
  setContentHeight() {
    const bodyHeight = DOM.body.outerHeight();
    const footerHeight = DOM.body.hasClass('footer_fixed') ? -10 : DOM.footer.outerHeight();
    const leftColHeight = DOM.leftCol.eq(1).outerHeight();
    const contentHeight = Math.max(bodyHeight, leftColHeight) - (DOM.navMenu.outerHeight() + footerHeight);
    DOM.rightCol.css('min-height', contentHeight);
  },

  openUpMenu() {
    DOM.sidebarMenu.find('li').removeClass('active');
    DOM.sidebarMenu.find('li ul').slideUp();
  },

  handleMenuItemClick() {
    DOM.sidebarMenu.find('a').on('click', function () {
      const $li = $(this).parent();
      if ($li.hasClass('active')) {
        $li.removeClass('active');
        $('ul:first', $li).slideUp();
      } else {
        if (!$li.parent().hasClass('child_menu')) {
          sidebar.openUpMenu();
        } else if (DOM.body.hasClass('nav-sm') && !$li.parent().hasClass('child_menu')) {
          sidebar.openUpMenu();
        }
        $li.addClass('active');
        $('ul:first', $li).slideDown();
      }
    });
  },

  handleMenuToggleClick() {
    DOM.menuToggle.on('click', () => {
      sidebar.toggleActiveState();
      DOM.body.removeClass('hide-menu-child').addClass('show-menu-child');
      DOM.body.removeClass('show-menu-child').addClass('hide-menu-child');//.addClass('animate__slideOutLeft');
    });
  },

  highlightCurrentPage() {
    let path = DOM.currentUrl;
    let found = DOM.sidebarMenu.find(`a[href="${path}"]`).length > 0;

    while (!found && path.lastIndexOf('/') > 0) {
      path = path.substring(0, path.lastIndexOf('/'));
      found = DOM.sidebarMenu.find(`a[href="${path}"]`).length > 0;
    }

    if (found) {
      const currentLink = DOM.sidebarMenu.find(`a[href="${path}"]`);
      currentLink.parent('li').addClass('current-page')
        .parents('ul').slideDown().parent().addClass(DOM.body.hasClass('nav-sm') ? 'active-sm' : 'active');
    }
  },

  copyChildMenuItems() {
    const parentMenuItems = $(selectors.parentNavItem);
  
    // Clear previous items
    DOM.childMenuContainer.empty();
  
    parentMenuItems.each(function () {
      const parentItem = $(this);
      const childMenu = parentItem.find(selectors.childMenuHide).first();
  
      // Check if any child item is active and add active class to parent
      if (childMenu.find('.active').length) {
        parentItem.addClass('current-page');
      }
  
      if (childMenu.length && parentItem.hasClass('current-page')) {
        const clonedChildMenu = childMenu.clone(true);
  
        // Create the new element to be appended
        const leftCloseIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-left" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0M4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5"/>
                               </svg>`;
        const navToggleDiv = $('<div>', { class: 'nav toggle' }).html(`<a href="#" id="menu_toggle">${leftCloseIcon}</a>`);
  
        clonedChildMenu.append(navToggleDiv);
        DOM.childMenuContainer.append(clonedChildMenu);
        DOM.body.removeClass('hide-menu-child').addClass('show-menu-child');
  
        // Ensure the display style is set correctly
        $(selectors.childNavContainer).css('display', '');//.addClass('animate__animated animate__slideInLeft');
      }
    });
  },  

  init() {
    // Uncomment to enable the menu item click handler
    this.handleMenuItemClick();
    this.handleMenuToggleClick();
    this.highlightCurrentPage();
    this.setContentHeight();
    this.copyChildMenuItems();
  }
};

$(document).on('turbo:load', function( e ) { 
  sidebar.init();
  $('#change-project.select2').select2({
      theme: 'bootstrap-5'
    }
  );
});
